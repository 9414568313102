<template>
  <section class="message-notice">

    <b-alert v-if="message"
             v-model="isShowNotice" dismissible
             v-bind:variant="notice.type" class="notice-alert"
             v-html="message">
    </b-alert>
  </section>
</template>

<script>
import $ from 'jquery';
import Server from "../utils/server";

export default {
  name: 'MessageNotice',
  data() {
    return {
      isShowNotice: false,
      notice: null,
    };
  },
  computed: {
    message() {
      if (this.notice) {
        return this.notice.message +
            '<button type="button" class="notice-alert-close" ' +
            'style="position:relative;top:2px;border:0;background:transparent;font-size:20px;color:#666666">×</button>';
      } else {
        return '';
      }
    }
  },
  setup() {
  },
  created() {  // 模板渲染成html前调用
    this.$root.$refs.MessageNotice = this;
  },
  mounted() {  // 模板渲染成html后调用
    const self = this;
    this.loadNoticeData().then(notice => {
      // 测试：
      // notice = {
      //   'message': '通知：<a href='https://www.tpqxb.com/imagevariation' target='_blank'>图片生图吧</a> 已上线，欢迎给我们提供改进意见',
      //   'type': 'primary'
      // }
      if (!notice) return;
      let localOrderNum = self.LocalStorage.getNoticeOrderNumber();
      if (localOrderNum && parseInt(localOrderNum) >= notice.orderNumber) return;
      setTimeout(() => self.showNotice(notice), 1000);
    })

    $(document)
        .off('click', '.notice-alert-close')
        .on('click', '.notice-alert-close', function () {
          self.isShowNotice = false;
          if (self.notice.orderNumber && self.notice.orderNumber > 0) {
            self.LocalStorage.setNoticeOrderNumber(self.notice.orderNumber)
          }
        });
  },
  components: {},
  methods: {
    showNotice(notice) {
      const self = this;

      this.notice = notice;
      if (!this.notice['type']) {
        this.notice['type'] = 'primary'
      }

      this.isShowNotice = true;
      if (self.noticeTimeoutId) clearTimeout(self.noticeTimeoutId);
      self.noticeTimeoutId = setTimeout(function () {
        self.isShowNotice = false;
      }, 15000);

    },
    loadNoticeData() {
      const self = this;
      return new Promise(function (resolve, reject) {
        const url = Server.getCloudServiceAddress() + '/notice/lastest';
        self.Ajax.get(url).then(resp => {
          if (resp.success) {
            let notice = resp.data.length > 0 ? resp.data[0] : null;
            resolve(notice)
          } else {
            reject();
          }
        }, () => {
          reject()
        });
      })
    },
    info(message) {
      this.showNotice({message: message, type: 'primary'})
    },
    success(message) {
      this.showNotice({message: message, type: 'success'})
    },
    error(message) {
      this.showNotice({message: message, type: 'danger'})
    },
    warning(message) {
      this.showNotice({message: message, type: 'warning'})
    }
  }
}

</script>

<style lang="scss" scoped>
.message-notice {
  .notice-alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    z-index: 10;
  }

  .alert-dismissible {
    padding-right: 1.25rem;
  }
}

</style>
