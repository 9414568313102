<!--会员套餐包-->
<template>
  <section class="membership-package">

    <!--    <h3 class="package-title" id="image-enlargement-pack-title">图片清晰吧 - 独立套餐</h3>-->
    <!--    <div class="packages">-->
    <!--      <div class="package"-->
    <!--           v-bind:class="{-->
    <!--                 'trial-package':(pack.sku==='001'),-->
    <!--                 'year-package':(pack.sku==='002' || pack.sku==='007'),-->
    <!--                 'month-package':(pack.sku==='003' || pack.sku==='005'),-->
    <!--                 'quarter-package':(pack.sku==='004' || pack.sku==='006') }"-->
    <!--           v-for="(pack,index) in dynamicImageEnargementPackages" :key="index">-->
    <!--        <div class="top">-->
    <!--          <div>{{ pack.name }}</div>-->
    <!--          <div class="price" v-if="pack.paymentCycle==='WEEK'">{{ pack.priceUnit }}{{ pack.price }} / 周</div>-->
    <!--          <div class="price" v-if="pack.paymentCycle==='MONTH'">{{ pack.priceUnit }}{{ pack.price }} / 月</div>-->
    <!--          <div class="price" v-if="pack.paymentCycle==='QUARTER'">{{ pack.priceUnit }}{{ pack.price }} / 季-->
    <!--          </div>-->
    <!--          <div class="price" v-if="pack.paymentCycle==='YEAR'">{{ pack.priceUnit }}{{ pack.price }} / 年</div>-->
    <!--        </div>-->
    <!--        <div class="center">-->
    <!--          <ul>-->
    <!--            <li>支持批量上传，图片加速处理</li>-->

    <!--            &lt;!&ndash; 由于 WEEK 中未放 checkbox 控件，致此套餐高度比其他两个低3px，因此这里单独设置 margin-bottom:10px;&ndash;&gt;-->
    <!--            <li v-if="pack.paymentCycle==='WEEK'" style="margin-bottom:10px;">-->
    <!--              <span class="diff">{{ pack.pictureEnlargementCountLimit }}</span> 张，用完<span-->
    <!--                style="color:#db2828;">不限张数</span>耗流量-->
    <!--              <span style="color:#db2828;">免送400MB</span>-->
    <!--            </li>-->
    <!--            <li v-if="pack.paymentCycle==='MONTH'">-->
    <!--              可用 <span class="diff">{{ pack.pictureEnlargementCountLimit }}</span> 张-->

    <!--              <b-form-checkbox-group class="pack-checkbox-group" size="sm"-->
    <!--                                     v-model="monthPackOptionSelected"-->
    <!--                                     :options="packOptions"-->
    <!--                                     @change="packOptionChange"-->
    <!--                                     name="month-pack-option-checkbox">-->
    <!--              </b-form-checkbox-group>-->
    <!--            </li>-->
    <!--            <li v-if="pack.paymentCycle==='QUARTER'">-->
    <!--              每月可用 <span class="diff">{{ pack.pictureEnlargementCountLimit }}</span> 张-->

    <!--              <b-form-checkbox-group class="pack-checkbox-group" size="sm"-->
    <!--                                     v-model="quarterPackOptionSelected"-->
    <!--                                     :options="packOptions"-->
    <!--                                     @change="packOptionChange"-->
    <!--                                     name="quarter-pack-option-checkbox">-->
    <!--              </b-form-checkbox-group>-->
    <!--            </li>-->
    <!--            <li v-if="pack.paymentCycle==='YEAR'">-->
    <!--              每月可用 <span class="diff">{{ pack.pictureEnlargementCountLimit }}</span> 张-->

    <!--              <b-form-checkbox-group class="pack-checkbox-group" size="sm"-->
    <!--                                     v-model="yearPackOptionSelected"-->
    <!--                                     :options="packOptions"-->
    <!--                                     @change="packOptionChange"-->
    <!--                                     name="year-pack-option-checkbox">-->
    <!--              </b-form-checkbox-group>-->
    <!--            </li>-->

    <!--            <li>单次批量上传最多 <span class="diff">{{ pack.pictureBatchUploadLimit }}</span> 张</li>-->

    <!--            <li v-if="pack.paymentCycle==='WEEK'">-->
    <!--              图像最小输出面积 2K({{ pack.pictureResolutionAreaLimit }}的乘积)，-->
    <!--              支持不低于 <span class="diff">4K</span> 输出-->
    <!--            </li>-->
    <!--            <li v-if="pack.paymentCycle==='MONTH'">-->
    <!--              图像最小输出面积 2K({{ pack.pictureResolutionAreaLimit }}的乘积)，-->
    <!--              支持不低于 <span class="diff">4K</span> 输出-->
    <!--            </li>-->
    <!--            <li v-if="pack.paymentCycle==='QUARTER'">-->
    <!--              图像最小输出面积 2K({{ pack.pictureResolutionAreaLimit }}的乘积)，-->
    <!--              支持不低于 <span class="diff">8K</span> 输出-->
    <!--            </li>-->
    <!--            <li v-if="pack.paymentCycle==='YEAR'">-->
    <!--              图像最小输出面积 2K({{ pack.pictureResolutionAreaLimit }}的乘积)，-->
    <!--              支持不低于 <span class="diff">8K</span> 输出-->
    <!--            </li>-->

    <!--            &lt;!&ndash;  <li>单图大小限制 {{ pack.pictureSizeLimit }}MB</li>&ndash;&gt;-->

    <!--            &lt;!&ndash; <li v-if="pack.paymentCycle==='YEAR'"><span class="diff">后续开放</span>一次性百张千张大批量处理入口</li>&ndash;&gt;-->
    <!--            &lt;!&ndash; <li v-else><span class="diff">不开放</span>一次性百张千张大批量处理入口</li>&ndash;&gt;-->

    <!--            &lt;!&ndash; <li>完成超 5 分钟将短信通知，每天限 {{ pack.pictureEnlargementSmsNotificationTimesLimit }} 次</li>&ndash;&gt;-->
    <!--            <li>此套餐仅可用于《图片清晰吧》</li>-->
    <!--          </ul>-->
    <!--        </div>-->
    <!--        <div class="bottom">-->
    <!--          <b-button class="buy-btn" v-on:click="showPaymentFormModal(pack)">-->
    <!--            立即购买-->
    <!--          </b-button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <h3 class="package-title" id="quantity-pack-title">
      <span style="font-size: 25px">通用点数套餐</span>
    </h3>
    <div>
      <div class="packages">
        <div class="package"
             v-bind:class="{ 'other-trial-package':(pack.sku==='Q01'),'other-quarter-package':(pack.sku==='Q02'),'other-year-package':(pack.sku==='Q03') }"
             v-for="(pack,index) in classif.quantityPackages" :key="index">

          <div v-if="pack.sku==='Q04'" style="position:absolute;top:4px;right:6px">
            <img src="/images/tuijian.svg" width="28px" title="推荐"/>
          </div>
          <div class="top">
            <div class="membership-name">{{ pack.name }} {{ pack.quantityMembershipPoints }}点</div>
            <div class="price" v-if="pack.paymentCycle==='WEEK'">
              <span class="price-unit">{{ pack.priceUnit }}</span>{{ pack.price }}<span class="period">/周</span>
            </div>
            <div class="price" v-if="pack.paymentCycle==='QUARTER'">
              <span class="price-unit">{{ pack.priceUnit }}</span>{{ pack.price }}<span class="period">/季</span>
            </div>
            <div class="price" v-if="pack.paymentCycle==='YEAR'">
              <span class="price-unit">{{ pack.priceUnit }}</span>{{ pack.price }}<span class="period">/年</span>
            </div>
          </div>
          <div class="bottom">
            <b-button class="buy-btn" v-on:click="showPaymentFormModal(pack)">
              <span v-if="pack.paymentCycle==='WEEK'">立即试用</span>
              <span v-else>立即购买</span>
            </b-button>
          </div>
        </div>
      </div>
      <div class="rules usage-rule">
        <div class="card">
          <div class="title">使用规则</div>
          <table>
            <tr style="background-color:#f4f5f5;font-weight:bold">
              <td>产品名称</td>
              <td>消耗</td>
            </tr>
            <tr>
              <td>图片清晰吧</td>
              <td>
                <div>1点 / 次</div>
                <div style="font-size: 12px;">(保留细节 2点/次)</div>
              </td>
            </tr>
            <tr>
              <td>智能扩图吧</td>
              <td>2点 / 次</td>
            </tr>
            <tr>
              <td>图片生图吧</td>
              <td>2点 / 次</td>
            </tr>
            <tr>
              <td>图片修复吧</td>
              <td>1点 / 次</td>
            </tr>
            <tr>
              <td>一键抠图吧</td>
              <td>1点 / 次</td>
            </tr>
            <tr>
              <td>人物卡通化</td>
              <td>4点 / 次</td>
            </tr>
            <tr>
              <td>图片压缩吧</td>
              <td>1点 / 次</td>
            </tr>
<!--            <tr>-->
<!--              <td>生成相似图</td>-->
<!--              <td>4点 / 次</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td>写真制作吧</td>-->
<!--              <td>160点 / 次</td>-->
<!--            </tr>-->
          </table>
        </div>
        <div class="card member-benefits">
          <div class="title">会员权益</div>
          <div class="content">
            <ul>
              <li><img src="/images/green-check.png" class="green-check" alt="">
                <span class="product-name">图片清晰吧</span>
                批处理一次最多上传<span class="highlight-number">15</span>张，试用版限制5张；最高支持不低于
                <span class="highlight-number">16K</span>输出，试用版仅支持不低于4K输出；上传分辨率限制7680*4320(8K)的乘积。
              </li>
              <li><img src="/images/green-check.png" class="green-check" alt="">
                <span class="product-name">智能扩图吧</span>
                批处理一次最多上传8张。
              </li>
              <li><img src="/images/green-check.png" class="green-check" alt="">
                <span class="product-name">图片生图吧</span>
                批处理一次最多上传8张。
              </li>
              <li><img src="/images/green-check.png" class="green-check" alt="">
                <span class="product-name">一键抠图吧</span>
                批处理一次最多上传15张。
              </li>
              <li><img src="/images/green-check.png" class="green-check" alt="">
                <span class="product-name">人物卡通化</span>
                批处理一次最多上传15张。
              </li>
              <li><img src="/images/green-check.png" class="green-check" alt="">
                <span class="product-name">图片压缩吧</span>
                批处理一次最多上传50张。
              </li>
              <li><img src="/images/green-check.png" class="green-check" alt="">
                所有套餐支持叠加使用，优先从快到期套餐中扣除点数。
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
    <!--        <div style="padding:15px 20px 5px 20px;text-align: center;">-->
    <!--            请注意：针对批量上传数用完的下载操作，我们正在制定流量收费规则！-->
    <!--        </div>-->

    <!-- 未登录用户付款 -->
    <b-modal id="membership-phone-form" ref="membership-phone-form" size="sm" centered hide-footer hide-header>
      <login-form :isbuying="true" v-bind:packageSku="selectionPackageSku"/>
    </b-modal>

    <!-- 登录用户付款 -->
    <b-modal id="logined-user-payment-form" ref="logined-user-payment-form" size="sm" centered hide-footer
             hide-header>
      <div class="renew-package-payment">
        <package-payment v-bind:packageSku="selectionPackageSku"/>
      </div>
    </b-modal>

    <!-- 微信付款二维码-->
    <b-modal id="weixin-payment-qrcode" ref="weixin-payment-qrcode" size="sm" centered hide-footer hide-header>
      <div style="text-align:center;">
        <img class="img" alt="微信付款二维码" v-bind:src="weixinQrcode" style="width:190px;">
        <div v-if="!isPaid">
          <span style="color:#dc3545;">{{ weixinPrice }}</span> 微信扫码
          <div style="font-size:12px;margin-top:8px;color:gray;">微信支付仅支持扫码</div>
        </div>
        <div v-else style="font-size:14px;color:#198f35;font-weight:bold;">付款成功，正在跳转......</div>
      </div>
    </b-modal>


    <b-alert v-model="showInfinitePackageTipMessageAlert" dismissible
             variant="primary" class="infinite-pack-msg-alert"
             v-html="infinitePackageTipMessage">
    </b-alert>
  </section>
</template>

<script>
import $ from 'jquery';
import LoginForm from "./LoginForm";
import Server from "../utils/server";
import PackagePayment from "./PackagePayment";
import common from "@/utils/common";

export default {
  name: 'MembershipPackage',
  components: {LoginForm, PackagePayment},
  data() {
    return {
      classif: {},
      selectionPackageSku: '',
      weixinQrcode: null,
      weixinPrice: null,
      isPaid: false,

      packOptions: [{'text': '不限张数', 'value': 'INFINITE', 'disabled': false}],
      monthPackOptionSelected: [],
      quarterPackOptionSelected: [],
      yearPackOptionSelected: [],
      showInfinitePackageTipMessageAlert: false
    };
  },
  computed: {
    dynamicImageEnargementPackages() {
      common.setSeoFooterElementVisible(false)

      let result = [];

      let packs = this.classif.imageEnargementPackages;
      for (var i = 0; i < packs.length; i++) {
        let pack = packs[i];

        switch (pack.sku) {
          case '001':
            result.push(pack);
            break;
          case '002':
            if (this.yearPackOptionSelected.length > 0) result.push(pack);
            break;
          case '007':
            if (this.yearPackOptionSelected.length === 0) result.push(pack);
            break;
          case '003':
            if (this.monthPackOptionSelected.length > 0) result.push(pack);
            break;
          case '005':
            if (this.monthPackOptionSelected.length === 0) result.push(pack);
            break;
          case '004':
            if (this.quarterPackOptionSelected.length > 0) result.push(pack);
            break;
          case '006':
            if (this.quarterPackOptionSelected.length === 0) result.push(pack);
            break
        }
      }

      return result;
    },
    infinitePackageTipMessage() {
      let amount = '';
      let paymentCycleName = '';
      if (this.monthPackOptionSelected.length > 0) {
        amount = '1GB';
        paymentCycleName = '月付';
      } else if (this.quarterPackOptionSelected.length > 0) {
        amount = '3GB';
        paymentCycleName = '季付';
      } else if (this.yearPackOptionSelected.length > 0) {
        amount = '15GB';
        paymentCycleName = '年付';
      }
      return `<span style="color:#db2828">${paymentCycleName}</span>不限张数说明：
                        超过每月可用张数后支持单张上传，耗低价流量，初始赠 <span style="color:#db2828">${amount}</span>`
    }
  },
  setup() {
  },
  created() {  // 模板渲染成html前调用
    this.weixinQrcode = null;
    this.weixinPrice = null;
    this.isPaid = false;
    this.$root.$refs.MembershipPackage = this;

    this.loadPackages();
  },
  mounted() {  // 模板渲染成html后调用

  },
  updated() {

  },
  methods: {
    getLoginUser: function () {
      return this.$root.$refs.RouteMenu.loginUser;
    },
    loadPackages: function () {
      const url = Server.getCloudServiceAddress() + '/membershipPackage/product';
      const resp = this.Ajax.get(url, false);
      if (resp.success) {
        this.classif = resp.data[0];
      }
    },

    showPaymentFormModal(pack) {
      this.selectionPackageSku = pack.sku;
      if (this.getLoginUser()) {
        this.showLoginedUserPaymentFormModal();
      } else {
        this.showPhoneFormModal()
      }
    },
    showPhoneFormModal() {
      this.$refs['membership-phone-form'].show()
    },
    hidePhoneFormModal() {
      this.$refs['membership-phone-form'].hide()
    },
    showLoginedUserPaymentFormModal() {
      this.$refs['logined-user-payment-form'].show()
    },
    hideLoginedUserPaymentFormModal() {
      this.$refs['logined-user-payment-form'].hide()
    },

    showWeixinPayQrcode(qrcode, price) {
      this.weixinQrcode = qrcode;
      this.weixinPrice = price;
      this.$refs['weixin-payment-qrcode'].show()
    },

    packOptionChange: function (selectedValues) {
      const self = this;

      // 显示无限张数提示
      self.showInfinitePackageTipMessageAlert = selectedValues.length > 0;

      // 重新勾选时，清理旧 timeout 防时间还没到就隐藏 alert
      if (this.showInfinitePackageTipMessageAlert) {
        if (self.timeoutId) clearTimeout(self.timeoutId);
        self.timeoutId = setTimeout(function () {
          self.showInfinitePackageTipMessageAlert = false;
        }, 8000);
      }

      // 清楚所有选项
      this.monthPackOptionSelected = [];
      this.quarterPackOptionSelected = [];
      this.yearPackOptionSelected = [];

      // 仅选中当前选择的
      switch (event.target.name) {
        case 'month-pack-option-checkbox':
          this.monthPackOptionSelected = selectedValues;
          break;
        case 'quarter-pack-option-checkbox':
          this.quarterPackOptionSelected = selectedValues;
          break;
        case 'year-pack-option-checkbox':
          this.yearPackOptionSelected = selectedValues;
          break;
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.membership-package {
  margin: 110px 15px 0 15px;
}

.package-title {
  margin: 25px 0;
  text-align: center;
  font-size: 28px;
}

.packages {
  display: block;
  text-align: center;
  margin-bottom: 20px;

  .package {
    width: 245px;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;

    /*height: 486px;*/
    display: inline-block;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
    //color: #fff;
    font-weight: 500;
    margin-top: 20px;
    /*margin: 0 auto;*/

    .top {
      text-align: center;
      padding-top: 20px;
      height: 100px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      line-height: 32px;
      font-size: 20px;

      .membership-name {
        //font-size: 15px;
        //color: #f65152;
        font-weight: normal;
        font-size: 17px;
        margin-bottom: 4px;
      }

      .price {
        font-weight: bold;
        font-size: 35px;
        padding: 5px 0;

        .price-unit {
          font-size: 14px;
        }

        .period {
          font-size: 18px;
        }
      }
    }

    .center {
      padding: 18px 20px 1px 0;
      text-align: left;

      ul {
        color: #8d8d8d;
        font-size: 14px;

        > li {
          margin-bottom: 7px;
        }
      }
    }

    .bottom {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      margin-bottom: 22px;
      margin-top: 16px;
    }
  }


  .buy-btn {
    background: linear-gradient(90deg, rgb(255, 119, 0), rgb(255, 73, 0));
    box-shadow: rgba(255, 119, 0, 0.2) 0px 9px 13px 0px;
    vertical-align: top;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    border: none;
    cursor: pointer;
  }

}

.rules {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;

  .card {
    padding: 19px;
    margin: 10px;

    border-radius: 8px;
    -webkit-border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);

    //background: #3f3e3e;
    //color: #f8ddc5;
    //margin: 25px 10px 0 10px;
    //font-size: 13px;
    //line-height: 22px;

    .title {
      margin: 0 10px 15px 10px;
      //color: #fcf1e6;
      font-size: 20px;
      font-weight: bold
    }

    table, table tr th, table tr td {
      border: 1px solid #dedede;
      padding: 4px 11px;
    }

    table {
      width: 100%;
      min-height: 25px;
      line-height: 25px;
      text-align: center;
      border-collapse: collapse;
    }

    .icon-check {
      color: #ff7700;
      margin-right: 2px;
    }
  }

  .member-benefits {
    max-width: 400px;

    .content {
      text-align: left;
      font-size: 15px;
    }

    ul > li {
      margin-bottom: 8px;
    }

    .green-check {
      width: 15px;
      position: relative;
      margin-right: 5px;
      top: -1px;
    }

    .product-name {
      font-weight: bold;
      color: #444;
    }

    .highlight-number {
      color: #ff4900
    }
  }
}

.pack-checkbox-group {
  display: inline-block;
  color: #DB2828;
  margin-left: 15px;
}

.pay-method {
  text-align: center;

  .pay-item {
    display: inline-block;
    cursor: pointer;
    text-align: center;
  }
}

.diff {
  color: #db2828;
}

.infinite-pack-msg-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  z-index: 1;
}

ul {
  list-style: none;
  padding: 0 15px;
}

@media only screen and (max-width: 360px) {
  .packages {
    .package {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .packages {
    .package {
      width: 96%;
    }
  }

  .usage-rule {
    .card {
      width: 96%;
    }
  }
}

</style>
