<template>
  <div class="style-selection-modal">
    <b-modal id="style-selection-modal" size="xl" scrollable
             header-class="custom-modal-header"
             ok-disabled
             :title-html=modalTitleHtml>

      <vue-loadmore
          :on-refresh="onRefresh"
          :on-loadmore="onLoad"
          :finished="isLoadStyleFinished"
          class="vue-loadmore-container">
        <div v-for="(item, i) of styleList" :key="i" class="variation-style-object">
          <div style="position:relative;">
            <div v-on:click="clickStyleItem(item)" style="overflow:hidden;">
              <img v-bind:src="item.images[0]" :alt="item.name" style="width:100%"/>
            </div>
            <div class="style-content">
              <div class="style-title">{{ item.name }}</div>
              <div class="style-desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </vue-loadmore>
    </b-modal>
  </div>
</template>

<script>

import Vue from 'vue';
import $ from 'jquery';
import Server from "@/utils/server";
import VueLoadmore from 'vuejs-loadmore';

Vue.use(VueLoadmore);

export default {
  name: 'StyleSelectionModal',
  data() {
    return {
      isLoadStyleFinished: false,
      isFetching: false,
      styleCategories: [],
      styleList: []
    }
  },
  computed: {
    modalTitleHtml() {
      let styleCategoryFragment = ''
      for (let i = 0; i < this.styleCategories.length; i++) {
        let styleCatetory = this.styleCategories[i]
        styleCategoryFragment += `<a class="tag variation-style-category-item ${i === 0 ? 'active' : ''}"
                   data-value="${styleCatetory}">${styleCatetory}
                </a>`
      }
      let styleCategoryContainerFragment = `<div class="variation-filter-wraper">
            ${styleCategoryFragment}
        </div>`

      return `<div style='display:flex;flex-direction:column;'>
                  <div style="font-size:17px;">风格</div>
                  <div>${styleCategoryContainerFragment}</div>
                </div>`
    }
  },
  setup() {
  },
  created() {  // 模板渲染成html前调用
    this.$root.$refs.StyleSelectionModal = this
  },
  mounted() {  // 模板渲染成html后调用
    const self = this

    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      this.onModalShown(modalId)
    })

    $(document)
        .off('click', '.variation-style-category-item')
        .on('click', '.variation-style-category-item', function () {
          self.clickStyleCategory()
        });
  },
  updated() {
  },
  methods: {
    openModal() {
      this.loadCategories()
      this.$bvModal.show('style-selection-modal')
    },
    hideModal() {
      this.$bvModal.hide('style-selection-modal')
    },
    onModalShown(modalId) {
      this.fetchUntilScreenHeight()
    },

    onRefresh(done) {
      if (this.isFetching) return;

      this.resetParams()
      this.fetchUntilScreenHeight()
      if (done) done();
    },
    onLoad(done) {
      this.fetch();
      done();
    },
    resetParams() {
      this.styleList = []           //清空现有数据

      //由于通过清空 list 来清理页面上的数据有一定的延迟，因此这里直接 jquery 操作了，防止后续 fetchUntilScreenHeight() 中由于高度已超直接退出不去查询了。
      // 若切换到其他路由组件再切回来还是有此问题，怎么办？老老实实延迟几秒再调用 fetchUntilScreenHeight 吧()
      //$('.variation-style-object').remove();

      this.searchAfter = null           //清空分页信息
      this.isLoadStyleFinished = false
    },
    fetchUntilScreenHeight(cycleNum) {
      const self = this;

      // 递归（延迟触发，以便让图片有渲染填充高度时间）
      setTimeout(() => {
        if (!cycleNum) cycleNum = 1;
        let contentHeight = $('#style-selection-modal .vue-loadmore-container').outerHeight();

        // 这个是浏览器可用区域高度，注意不能用设备的屏幕高度，因为浏览器是可以缩放的
        let windowInnerHeight = window.innerHeight;
        //打印测试数据
        //console.log(`>>> contentHeight:${contentHeight}, windowInnerHeight:${windowInnerHeight}`);

        // 退出条件1：数据显示高度超过浏览器可用区域高度（推荐调用此段代码前，若删除数据，一定要附加 jquery 直接删，双向绑定的删除有延迟，导致这里直接 return）。
        if (windowInnerHeight < contentHeight + 40) {  //这 40 作为模态框上下空出来的高度
          //console.log(">>> Sufficient height.")
          return;
        }

        self.fetch().then((reps) => {
          // 退出条件2：服务器返回元素个数为0
          var data = reps.data;
          if (!data || data.length === 0) return;
          // 退出条件3：循环次数超过5
          if (cycleNum >= 5) return;

          self.fetchUntilScreenHeight(cycleNum++);
        }, err => {
          if (err) console.error(err);
        });
      }, 100)
    },
    fetch() {
      let self = this;
      if (this.isFetching) return;

      let selectedStyleCategory = this.getSelectionStyleCategory()
      let category = selectedStyleCategory ? selectedStyleCategory : ''

      let searchAfter = this.searchAfter ? this.searchAfter : ''
      let url = `${Server.getCloudServiceAddress()}/api/imageVariations/style?category=${category}&searchAfter=${searchAfter}`;

      return new Promise(function (resolve, reject) {
        self.isFetching = true
        self.Ajax.get(url).then((reps) => {
          // 设置 fetching 状态
          self.isFetching = false
          // 存到列表以显示
          for (let item of reps.data) {
            self.styleList.push(item)
          }
          // 设置所有数据已加载完成标记，以便分页插件不再刷新数据（小于后台设置的一页20条，也表示数据加载完成了）
          if (!reps.data || reps.data.length === 0 || reps.data.length < reps.pageSize) {
            self.isLoadStyleFinished = true;
          }

          // 设置 searchAfter 用于显示下一页
          if (reps.data && reps.data.length > 0) {
            self.searchAfter = reps.data[reps.data.length - 1].sortOrder
          }

          resolve(reps)
        }, err => {
          self.isFetching = false
          if (err) console.error(err);
          reject()
        });
      })
    },
    clickStyleCategory() {
      if (this.isFetching) return;

      let $target = $(event.target)
      let isTag = $target.hasClass('tag')
      if (!isTag) return

      // 设置所有 tag 设为不激活
      $target.closest('.variation-filter-wraper').find('.active').removeClass('active')

      // 激活指定 tag
      let isActive = $target.hasClass('active')
      if (!isActive) {
        $target.addClass('active')
      }

      this.onRefresh()
    },
    clickStyleItem(variationStyle) {
      this.$parent.addStyle(variationStyle)
      this.hideModal()
    },
    getSelectionStyleCategory() {
      let selectionTags = []

      let selections = $('.variation-filter-wraper .tag.active')
      for (let i = 0; i < selections.length; i++) {
        selectionTags.push($(selections[i]).data('value'))
      }

      return selectionTags
    },

    loadCategories() {
      if (this.styleCategories && this.styleCategories.length > 0) return;

      const url = this.Server.getCloudServiceAddress() + '/api/imageVariations/style/category';
      const resp = this.Ajax.get(url, false);
      if (resp && resp.success) {
        this.styleCategories = resp.data
      }
    }
  }
}

</script>

<style lang="scss">

.variation-style-object {
  display: inline-block;
  vertical-align: top; /*顶部对齐*/
  width: calc(100% / 5 - 16px - 0.01px); /*一行5张*/
  margin-left: 0;
  margin-right: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  padding: 4px;

  border: 1px solid #e1e1e1;
  border-radius: 6px;
  -webkit-border-radius: 6px;

  .style-content {
    padding: 3px 5px;

    .style-title {
      font-size: 13px;
      color: #0f0f0f;
      font-weight: 500;
      margin-top: 6px;
      margin-bottom: 3px;
    }

    .style-desc {
      font-size: 13px;
      line-height: 18px;
      color: #9b9b9b;
    }
  }

  img {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    transition: transform 0.3s ease; /* 添加过渡效果 */
  }

  img:hover {
    transform: scale(1.3); /* 鼠标悬停时放大1.2倍 */
  }
}

.variation-filter-wraper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  //justify-content: space-between;
  margin-top: 24px;
  margin-left: -10px;
  cursor: pointer;
  background-color: #fff;

  .tag {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    font-size: 15px;
    font-weight: 500;
    color: #a4a4a4;
    //background-color: #fafafa;
    padding: 0;
    margin: 0 10px 10px 10px;
    min-width: 12px;
    cursor: pointer;
    //border-radius: 2px;
    //-webkit-border-radius: 2px;
    border-bottom: 4px solid #fff;
  }

  .tag.active {
    color: #333;
    //background-color: #f6faff;
    border-bottom: 4px solid #007bff;
  }
}

#style-selection-modal {
  .modal-header {
    z-index: 1;
    background: #fff;

    .modal-title {
      width: 100%;
    }
  }

  .modal-body {
    margin-top: -15px;
  }

  footer {
    height: 1px;
    padding: 6px;
    border-top: 0;

    button {
      display: none;
    }
  }
}

///* 整个滚动条 */
//::-webkit-scrollbar {
//  width: 10px;
//}
//
///* 滚动条的滚动区域（轨道） */
//::-webkit-scrollbar-track {
//  border-radius: 2px;
//}
//
///* 滚动条的可拖拽部分（滑块） */
//::-webkit-scrollbar-thumb {
//  background: #909090;
//  border-radius: 10px;
//  visibility: visible; /* 影藏滚动条样式 */
//  border: solid 4px #fff; /* 将 width 边框设白色看起来像有 padding 效果 */
//}

/*缩放宽度不小于200px，否则 width: calc(100% / n - 16px - 0.01px) 这里的 n递减，并添加新的 @media only screen and (max-width: x) */

@media only screen and (max-width: 1200px) {
  .variation-style-object {
    width: calc(100% / 4 - 16px - 0.01px);
  }
}

@media only screen and (max-width: 992px) {
  .variation-style-object {
    width: calc(100% / 3 - 16px - 0.01px);
  }
}

@media only screen and (max-width: 576px) {
  .variation-style-object {
    width: calc(100% / 2 - 16px - 0.01px);
  }
}

</style>
