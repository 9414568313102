import Common from "./common";
import Server from "./server";
import LocalStorage from "./localstorage";
import Ajax from "./ajax";
import $ from 'jquery';
import OSS from 'ali-oss';
import common from "@/utils/common";
import Oss from "@/utils/oss";
import Aliyun from "@/utils/aliyun";
import moment from 'moment'

export default {
    fileUploadComponent: null,
    productCategory: null,
    socket: {},
    setPrivateImage(fileUploadComponent, type, objectKey) {
        const self = this
        self.fileUploadComponent = fileUploadComponent

        let downloadUrl = Oss.getOssPrivateDownloadUrl(objectKey)

        // type 值与赋值变量名要保持一致（可直接复制到下面的 setInterval 里）
        if (type === 'imageUrl') self.fileUploadComponent.imageUrl = downloadUrl
        else if (type === 'enlargeFaceImage') self.fileUploadComponent.enlargeFaceImage = downloadUrl
        else if (type === 'detailPreservationImage') self.fileUploadComponent.detailPreservationImage = downloadUrl
        else if (type === 'photoAlbumImage') self.fileUploadComponent.photoAlbumImage = downloadUrl
        else if (type === 'similarImage') self.fileUploadComponent.similarImage = downloadUrl

        const refreshIntervalHours = 8
        setInterval(() => {
            try {
                // type 值与赋值变量名要保持一致
                if (type === 'imageUrl') self.fileUploadComponent.imageUrl = downloadUrl
                else if (type === 'enlargeFaceImage') self.fileUploadComponent.enlargeFaceImage = downloadUrl
                else if (type === 'detailPreservationImage') self.fileUploadComponent.detailPreservationImage = downloadUrl
                else if (type === 'photoAlbumImage') self.fileUploadComponent.photoAlbumImage = downloadUrl
                else if (type === 'similarImage') self.fileUploadComponent.similarImage = downloadUrl
            } catch (e) {
                console.log(e)
            }
        }, 1000 * 60 * 60 * refreshIntervalHours)
    },
    setBannerImage(fileUploadComponent, freeUserObjectKey, vipUserObjectKey) {
        const self = this
        self.fileUploadComponent = fileUploadComponent

        if (self.fileUploadComponent.isQuantityMembership && self.fileUploadComponent.isQuantityMembership()) {
            self.fileUploadComponent.bannerImage = Oss.getOssPrivateDownloadUrl(vipUserObjectKey)
        } else {
            self.fileUploadComponent.bannerImage = Oss.getOssPrivateDownloadUrl(freeUserObjectKey)
        }

        const refreshIntervalHours = 8
        setInterval(() => {
            try {
                if (self.fileUploadComponent.isQuantityMembership && self.fileUploadComponent.isQuantityMembership()) {
                    self.fileUploadComponent.bannerImage = Oss.getOssPrivateDownloadUrl(vipUserObjectKey)
                } else {
                    self.fileUploadComponent.bannerImage = Oss.getOssPrivateDownloadUrl(freeUserObjectKey)
                }
            } catch (e) {
                console.log(e)
            }
        }, 1000 * 60 * 60 * refreshIntervalHours)
    },

    startConnecting(fileUploadComponent, productCategory) {
        this.fileUploadComponent = fileUploadComponent
        this.productCategory = productCategory

        // 若断开，每隔15秒会重连
        let retryInterval = 15
        setInterval(this.connectFunction().bind(this), 1000 * retryInterval);
    },
    connectFunction() {
        try {
            // 实时获取登录会员的 token
            const token = this.fileUploadComponent.getLoginUser() ? this.fileUploadComponent.getLoginUser().sessionId : '';
            this.socket = this.doConnect(this.socket, token, this.productCategory);
        } catch (e) {
            Common.closeWebsocketConnection(this.socket);
            console.error('>>> WebSocket连接异常，详情：' + e.message);
        }
        return this.connectFunction;
    },
    doConnect(socket, token) {
        //if (socket.serverSessionId) console.log('>>> webSocketSessionId: ' + socket.serverSessionId)

        const self = this

        // 1. 正在建立连接的 socket 让它飞一会儿
        if (socket.readyState === 0) return socket;

        // 2. 连接已建立并可用，那就直接用它呗，用不着去创建
        if (socket.readyState === 1) {

            // 检查控件是否不可用，若是则恢复可用
            if (this.fileUploadComponent.messageType === 'LOCAL_SERVICE_UNAVAILABLE') {
                this.fileUploadComponent.resetUpload();
            }

            return socket
        }

        // 3. 不是上面两种状态，则创建新 socket 连接
        try {
            this.fileUploadComponent.disabled = true;
            if (this.fileUploadComponent.messageType !== 'LOCAL_SERVICE_UNAVAILABLE') {  //若界面已出现"服务暂不可用"，动作虽是“正在连接”也不要显示出来，这样对用户更友好。
                this.fileUploadComponent.message = '<span style="color:#727a79">正在连接......</span>';
            }
            this.fileUploadComponent.messageType = 'LOCAL_SERVICE_UNAVAILABLE'

            // 生成 sockjsUrl 地址
            const sockjsUrl = Server.getCloudServiceWebsocketAddress() + `/ws/sockjs?type=${this.productCategory}&token=${token}`;
            // 生成 websocketUrl 地址
            let websocketUrl = Server.getCloudServiceWebsocketAddress() + `/ws?type=${this.productCategory}&token=${token}`;

            if ('WebSocket' in window) {
                socket = new WebSocket(websocketUrl);
                // 创建新 socket 实例后会立即执行下面的代码，此时一般 socket.readyState == 0
                //console.log(">>> 新 WebSocket 实例被创建")
            } else if ('MozWebSocket' in window) {
                // eslint-disable-next-line no-undef
                socket = new MozWebSocket(websocketUrl);
            } else {
                // eslint-disable-next-line no-undef
                socket = new SockJS(sockjsUrl);
            }
            socket.uuid = Common.generateUuid();

            // eslint-disable-next-line no-unused-vars
            socket.onopen = function (evnt) {
                //console.log('>>> 新连接 token：' + Common.getQueryVariable(socket.url, 'token'));
            };

            socket.onmessage = function (message) {
                //const self = this;
                const d = JSON.parse(message.data);
                if (!d) return;

                const type = d.type;
                //console.log('>>> type: ' + type);

                // （1）公共消息
                switch (type) {
                    case 'WEB_SOCKET_SESSION_ID':
                        self.connectSuccess(d.socketSessionId)
                        return;
                    case 'REFRESH_PAGE':
                        Common.toastDanger(self.fileUploadComponent.$bvToast, '登录警告', d.message);
                        self.fileUploadComponent.makeToast('danger');
                        setTimeout(function () {
                            self.fileUploadComponent.$router.go(0);
                        }, 3000);
                        return;
                }

                // （2）以下消息一旦出现，需前端手动点击 Reset 按钮才能回到上传状态，但对于没有 reset 的按钮直接忽略此处逻辑
                let previousMessageType = self.fileUploadComponent.messageType
                let hasResetBtn = ['IMAGE_CLEANER'].indexOf(self.productCategory) === -1
                if (hasResetBtn) {
                    if (type !== 'GENERIC_ANONYMOUS_USER_UPLOAD_STATUS') {
                        var isPreviousErrorMsg = ['GENERIC_ERROR', 'GENERIC_BATCH_UPLOAD_ERROR'].indexOf(previousMessageType) !== -1;
                        if (isPreviousErrorMsg) return;
                    }
                }

                // （3）业务消息处理
                const batchResult = d.batchResult;
                const errors = batchResult ? batchResult.errorList : null;

                switch (type) {
                    case 'GENERIC_INFO':
                        self.fileUploadComponent.showMessage(type, d.message);
                        break;
                    case 'GENERIC_ERROR':
                        self.fileUploadComponent.showMessage(type, errors[0]);
                        self.fileUploadComponent.multipleUploading = true;
                        break;
                    case 'GENERIC_ANONYMOUS_USER_UPLOAD_STATUS':
                        if (!self.fileUploadComponent.isQuantityMembership()) {
                            var anonStatusObj = JSON.parse(d.message);
                            self.fileUploadComponent.disabled = anonStatusObj.isDisableUpload;

                            if (anonStatusObj.isDisableUpload) {
                                // 对于匿名用户的重要消息，这里指：错误信息、带下载链接的处理结果。这些都不能自动消失，因此不能被匿名用户状态消息覆盖
                                var isImportantMsg = ['GENERIC_BATCH_UPLOAD_PROGRESS', 'GENERIC_BATCH_UPLOAD_SUCCESS',
                                    'GENERIC_ERROR', 'GENERIC_BATCH_UPLOAD_ERROR', 'GENERIC_BATCH_PROCESS_RESULT'].indexOf(previousMessageType) !== -1;
                                if (!isImportantMsg) {
                                    // 显示匿名用户状态消息
                                    self.fileUploadComponent.resetUpload();
                                    self.fileUploadComponent.showMessage(type, anonStatusObj.message);
                                    setTimeout(() => {
                                        self.fileUploadComponent.showMessage(type, anonStatusObj.message);
                                    }, 150)
                                }
                            } else {
                                // 若上一个是匿名用户状态消息，则清除显示的消息
                                if (previousMessageType === 'GENERIC_ANONYMOUS_USER_UPLOAD_STATUS') {
                                    self.fileUploadComponent.message = '';
                                    self.fileUploadComponent.messageType = '';
                                    self.fileUploadComponent.batchUploadProgressMessage = '';
                                }
                            }
                        }
                        break;
                    case 'GENERIC_BATCH_UPLOAD_PROGRESS':
                        // 此判断主要用于避免当两张图一起上传时，第一张成功了但还没返回 GENERIC_BATCH_UPLOAD_PROGRESS，此时第二张前端检查失败，
                        // 此时用户点"重新上传"将 messageType 清掉了，接着服务器才返回第一张图的上传进度消息，此时这个上传进度就会显示在页面上！
                        if (self.fileUploadComponent.messageType) {
                            self.fileUploadComponent.showMessage(type);
                            self.fileUploadComponent.batchUploadProgressMessage = '正在上传 ' + batchResult.uploadedCount + '/' + batchResult.total;
                            self.fileUploadComponent.multipleUploading = true;
                        }
                        break;
                    case 'GENERIC_BATCH_UPLOAD_SUCCESS':
                        self.fileUploadComponent.showMessage(type);
                        self.fileUploadComponent.batchUploadProgressMessage = '完成上传 ' + batchResult.uploadedCount + '/' + batchResult.total;
                        self.fileUploadComponent.message = d.message;
                        self.fileUploadComponent.multipleUploading = true;
                        break;
                    case 'GENERIC_BATCH_UPLOAD_ERROR':
                        self.fileUploadComponent.showMessage(type);
                        var firstError = (errors && errors.length > 0) ? errors[0] : '上传失败';
                        self.fileUploadComponent.batchUploadProgressMessage = firstError;
                        self.fileUploadComponent.multipleUploading = true;
                        break;
                    case 'GENERIC_BATCH_PROCESS_WAITING':
                        // 若在等待时刷新了页面，此时这里的 self.fileUploadComponent.messageType 可能是""空字符串
                        if (['GENERIC_BATCH_PROCESS_PROGRESS'].indexOf(self.fileUploadComponent.messageType) === -1) {
                            self.renderWaitingBatchResult(batchResult);
                        }
                        break;
                    case 'GENERIC_BATCH_PROCESS_PROGRESS':
                        if (['GENERIC_INFO', 'GENERIC_BATCH_UPLOAD_PROGRESS', 'GENERIC_BATCH_PROCESS_RESULT'].indexOf(self.fileUploadComponent.messageType) === -1 && self.fileUploadComponent.messageType) {
                            self.renderProcessingBatchResult(batchResult);
                        }
                        break;
                    case 'GENERIC_BATCH_PROCESS_RESULT':
                        if (['GENERIC_BATCH_PROCESS_WAITING', 'GENERIC_BATCH_PROCESS_RESULT'].indexOf(self.fileUploadComponent.messageType) === -1) {
                            self.renderDownloadLink(batchResult);
                        }
                        break;
                }
                self.dispatchWebsocketCustomEvent(type, self.productCategory, batchResult)
            };
            socket.onerror = function (e) {
                self.connectFailure()
                Common.printWebSocketStatus(socket);
            };
            socket.onclose = function () {
                self.connectFailure()
                //console.log('>>> 连接已关闭');
            }
        } catch (e) {
            console.error("连接失败：" + e.message);
            Common.closeWebsocketConnection(socket);
            return socket
        }
        return socket;
    },
    connectSuccess(websocketSessionId) {
        // 设置 websocketSessionId
        this.socket.serverSessionId = websocketSessionId;
        // 用 websocketSessionId 来设置 clientId
        if (!this.fileUploadComponent.LocalStorage.getClientId()) this.fileUploadComponent.LocalStorage.setClientId(websocketSessionId);
        // 设置消息
        this.fileUploadComponent.message = null;
        this.fileUploadComponent.messageType = null;
        // 启用上传控件
        this.fileUploadComponent.disabled = false;

        // 获取会员最新的 batchResult，根据值渲染不同状态（处理中、已完成、排队数）
        if (this.fileUploadComponent.isQuantityMembership()) {
            let skipRequestBatchResultProducts = ['IMAGE_CLEANER']
            let isSkiped = skipRequestBatchResultProducts.indexOf(this.productCategory) !== -1
            if (!isSkiped) {
                var url = Server.getCloudServiceAddress() + `/ossBatchItem/batchResult?productCategory=${this.productCategory}`;
                this.fileUploadComponent.Ajax.get(url).then(resp => {
                    if (resp.success) {
                        if (resp.data && resp.data.length > 0) {
                            let batchResult = resp.data[0]
                            const isFinished = !!batchResult.finishedTime;
                            if (isFinished) {
                                this.renderDownloadLink(batchResult);
                            } else {
                                if (batchResult.waitingCount > 0) {   //会员等待
                                    this.renderWaitingBatchResult(batchResult)
                                } else {  //会员处理中
                                    this.renderProcessingBatchResult(batchResult);
                                }
                            }
                        }
                    }
                }, () => {
                    console.log(">>> 获取任务状态异常")
                });
            }
        }
        // 获取免费版的匿名任务状态
        else {
            //仅对单任务耗时超过10秒的发送 "请等待最多 x 秒任务被占用......" 消息，目前仅用于《图片清晰吧》
            if (this.productCategory === 'IMAGE_ENLARGE') {
                url = Server.getCloudServiceAddress() + `/ossBatchItem/anonymousTaskResult?productCategory=${this.productCategory}`;
                this.fileUploadComponent.Ajax.get(url).then(resp => {
                    if (resp.success) {
                        if (resp.data && resp.data.length > 0) {
                            let anonymousTaskResult = resp.data[0]
                            this.renderAnonymousTaskResult(anonymousTaskResult)
                        }
                    }
                }, () => {
                    console.log(">>> 获取免费版任务状态异常")
                });
            }
        }

        // 启动 Websocket 心跳
        common.doSocketHeartbeat(this.socket);
        // 发送 Websocket 连接成功事件
        this.dispatchWebsocketCustomEvent('WEBSOCKET_CONNECT_SUCCESS', this.productCategory, null)
    },
    connectFailure() {
        const self = this
        // 重置上传控件
        this.fileUploadComponent.resetUpload();
        // 禁用上传控件
        self.fileUploadComponent.disabled = true;
        // message 会因调用 resetUpload() 切换控件而显示不出来，因此这里延迟150ms再设置一次，必须 messageType、message 都设置才行
        // 此外，延迟150ms也解决了火狐浏览器点击下载或切换页面瞬间出现"服务暂不可用"的问题。
        setTimeout(() => {
            self.fileUploadComponent.messageType = 'LOCAL_SERVICE_UNAVAILABLE';  // LOCAL_xxx 类型消息是前端仅有的，服务器端没有
            self.fileUploadComponent.message = '<b-icon icon="exclamation-circle-fill" variant="danger"></b-icon> 服务暂不可用';
        }, 150)
        // 强制更新界面（不起作用）
        //this.$nextTick(() => { console.log('DOM updated'); });
        // 发送 Websocket 连接关闭事件
        this.dispatchWebsocketCustomEvent('WEBSOCKET_CONNECT_FAILURE', this.productCategory, null)
    },
    monitorUserLoginChangeRealTime() {
        const self = this

        // 此方法检查用户登录、登出行为，然后触发关闭当前的 Socket 连接。

        setInterval(function () {
            if (!self.socket || !self.socket.url) return;

            try {
                // 当前页面登录 token 值（即 httpSessionId）
                const token = self.fileUploadComponent.getLoginUser() ? self.fileUploadComponent.getLoginUser().sessionId : '';

                // 当前 socket url 上的 token 值
                const urltoken = Common.getQueryVariable(self.socket.url, 'token');

                // token 不一致则关闭 socket（发生在用户登录、退出动作时）
                if (token !== urltoken) {
                    Common.closeWebsocketConnection(self.socket);
                    //console.log(">>> 因 Token 发生变化 Socket 连接被关闭");
                }
            } catch (e) {
                console.log('状态检查错误')
            }
        }, 1000);
    },

    checkFile(maxFileSizeMBOfAnonymousUser, maxFileSizeMBOfMembership, reduceSizeUntilMB, error, file) {
        const self = this;

        return new Promise(function (resolve, reject) {

            if (error) {
                self.fileUploadComponent.showErrorMessage(error.main + " " + error.sub);
                self.fileUploadComponent.batchUploadProgressMessage = file.filename;
                self.fileUploadComponent.isPondBatchAborting = true;
                reject();
                return;
            }

            const fi = file.file;

            // 1. 文件大小和文件类型检查，控件会自动完成，这里不用去检查了。
            //const fi = file.file;
            //const fileSize = fi.size;
            //const fileName = fi.name;
            //const contentType = fi.type;

            // 1. 文件大小限制
            const fileSize = fi.size;
            if (maxFileSizeMBOfAnonymousUser) {
                if ((fileSize > maxFileSizeMBOfAnonymousUser * 1024 * 1024) && !self.fileUploadComponent.isQuantityMembership()) {
                    self.fileUploadComponent.showErrorMessage(`免费版单图限制 ${maxFileSizeMBOfAnonymousUser}MB`);
                    self.fileUploadComponent.setCustomProgress(file, 'error', '上传出错');
                    self.fileUploadComponent.batchUploadProgressMessage = file.filename;
                    self.fileUploadComponent.isPondBatchAborting = true;
                    reject();
                    return;
                }
            }
            if (maxFileSizeMBOfMembership) {
                if ((fileSize > maxFileSizeMBOfMembership * 1024 * 1024) && self.fileUploadComponent.isQuantityMembership()) {
                    self.fileUploadComponent.showErrorMessage(`会员单图限制 ${maxFileSizeMBOfMembership}MB`);
                    self.fileUploadComponent.setCustomProgress(file, 'error', '上传出错');
                    self.fileUploadComponent.batchUploadProgressMessage = file.filename;
                    self.fileUploadComponent.isPondBatchAborting = true;
                    reject();
                    return;
                }
            }

            // 2. 文件超过指定大小，则标记需要通过降低分辨率来降低图片大小
            if (reduceSizeUntilMB) {
                if (file.file.size > reduceSizeUntilMB * 1024 * 1024) {
                    file.file.reduceSizeUntilMB = reduceSizeUntilMB
                }
            }

            // 3. WebSocketSessionId 参数检查
            const ssid = self.socket.serverSessionId;
            if (!ssid) {
                self.fileUploadComponent.setCustomProgress(file, 'error', '上传出错');
                self.fileUploadComponent.isPondBatchAborting = true;
                // 延迟原因：若网速快，会导致控件在上传，底部错误消息已出来
                setTimeout(function () {
                    self.fileUploadComponent.showErrorMessage('网络中断，请刷新页面后再试');
                }, 1000);
                reject();
                return;
            }

            resolve(file);
        })
    },
    uploadToAliyun(ossDir, fieldName, file, metadata, load, error, progress, abort, disableCallback, fileUuid) {
        let self = this

        return new Promise(function (resolve, reject) {
            let step = 4, curStep = 1;
            if (progress) progress(true, curStep, step);
            Aliyun.getAliossStsToken().then((credentials) => {
                if (progress) progress(true, ++curStep, step);
                Aliyun.aliossUploadToShanghai(file, credentials, ossDir, fileUuid).then((callbackReq) => {
                    if (progress) progress(true, ++curStep, step);

                    // 提示：返回的 callbackReq 里已设置 objectName、fileName 值
                    callbackReq.cid = self.fileUploadComponent.LocalStorage.getClientId();
                    callbackReq.ssid = self.socket.serverSessionId;
                    callbackReq.loginName = self.fileUploadComponent.getLoginUser() ? self.fileUploadComponent.getLoginUser().loginName : '';
                    callbackReq.batchId = metadata['batchId'];
                    callbackReq.batchSize = metadata['batchSize'];
                    callbackReq.uploadOptions = self.fileUploadComponent.uploadOptionSelected;
                    callbackReq.category = self.productCategory;
                    callbackReq.imageNumber = self.fileUploadComponent.imageNumber;
                    callbackReq.prompt = self.fileUploadComponent.prompt;
                    callbackReq.variationIntensity = self.fileUploadComponent.variationIntensity;
                    if (self.fileUploadComponent.variationStyles) callbackReq.variationStyles = self.fileUploadComponent.variationStyles.map(item => item.code);
                    callbackReq.outputImageWidth = self.fileUploadComponent.outputImageWidth;
                    callbackReq.outputImageHeight = self.fileUploadComponent.outputImageHeight;
                    callbackReq.padLeftRatio = self.fileUploadComponent.padLeftRatio;
                    callbackReq.padTopRatio = self.fileUploadComponent.padTopRatio;
                    if (!disableCallback) {
                        Aliyun.aliossUploadCallback(callbackReq).then((resp) => {
                            if (progress) progress(true, ++curStep, step);
                            if (load) load();
                            resolve(callbackReq)
                        }, (err) => {
                            reject(err)
                        });
                    } else {
                        resolve(callbackReq)
                    }
                }, (err) => {
                    reject(err)
                })
            }, (err) => {
                reject(err)
            });
        })
    },

    renderProcessingBatchResult(batchResult) {
        const errCountColor = batchResult.errorCount > 0 ? 'color:#db2828' : '';
        const msg = '正在处理您的图片，成功 ' + batchResult.successCount + ' 失败 <span style="' + errCountColor + '">' +
            batchResult.errorCount + '</span>';
        this.fileUploadComponent.showMessage('GENERIC_BATCH_PROCESS_PROGRESS', msg, false);
        this.batchResult = {finishedTime: '', uploadedCount: 0, successCount: 0, errorCount: 0, errorList: []};
        this.fileUploadComponent.batchUploadProgressMessage = '完成上传 ' + batchResult.uploadedCount + '/' + batchResult.total;
        this.fileUploadComponent.multipleUploading = true;
    },
    renderWaitingBatchResult(batchResult) {
        // 提示：排队等待功能，仅会员才会有，免费版无此功能
        var msg = '前面有 <span style="color:#db2828;">' + batchResult.waitingCount + '</span> 张图正在处理，您可等待或关闭页面，稍后查看结果';
        this.fileUploadComponent.showMessage('GENERIC_BATCH_PROCESS_WAITING', msg);
        this.fileUploadComponent.batchUploadProgressMessage = '完成上传 ' + batchResult.uploadedCount + '/' + batchResult.total;
        this.fileUploadComponent.multipleUploading = true;
    },
    renderDownloadLink(batchResult) {
        const self = this;
        if (!batchResult) return;
        this.fileUploadComponent.resetUpload();

        // resetUpload() 后这里必须延迟一点时间，否则设置不生效
        setTimeout(() => {
            self.fileUploadComponent.showMessage('GENERIC_BATCH_PROCESS_RESULT');
            batchResult.finishedTime = moment(batchResult.finishedTime).format("MM-DD HH:mm");
            self.fileUploadComponent.batchCompressing = batchResult.compressing;
            self.fileUploadComponent.batchResult = batchResult;
        }, 150)
    },
    renderAnonymousTaskResult(anonymousTaskResult) {
        this.fileUploadComponent.disabled = anonymousTaskResult.isDisableUpload;
        this.fileUploadComponent.showMessage('GENERIC_ANONYMOUS_USER_UPLOAD_STATUS', anonymousTaskResult.message);
    },
    doDownloadFile() {
        // 这里直接通过 uuid/originalFileName 方式上传到阿里云OSS，所以下载保留了原始文件名的。
        //const attName = this.fileUploadComponent.Common.getQueryVariable(this.fileUploadComponent.url, 'attname');

        // 是否OSS地址
        const url = this.fileUploadComponent.batchResult.url ? this.fileUploadComponent.batchResult.url :
            this.fileUploadComponent.batchResult.OssUrl;
        const isOssUrl = url && url.indexOf('aliyuncs.com') !== -1;

        if (isOssUrl) {
            // 下载
            Common.downloadFile(url, null);
            // 点击下载就重置上传控件
            this.fileUploadComponent.resetUpload();
        } else {
            // 不是OSS地址则先确定网络通畅后再下载（IP变更会致网络不通，此时经测10秒后会结束请求）
            // 注意： accessibleUrl 前面部分用的下载URL里的前面部分，以确保下载资源在不同下载服务上时仍可检测到。
            let networkReachable = false;
            let accessibleUrl = url.substring(0, url.indexOf('/pictureProcessor/')) + `/pictureProcessor/download/accessible`;
            try {
                let resp = this.fileUploadComponent.Ajax.get(accessibleUrl, false);
                if (resp && resp.success) {
                    networkReachable = resp.data[0];
                }
            } catch (e) {
                console.log(">>> 网络异常")
            }

            if (networkReachable) {
                // 下载
                Common.downloadFile(url, null);
                // 点击下载就重置上传控件
                this.fileUploadComponent.resetUpload();

                // 不是阿里云地址，则3秒后进行询问"下载失败？"，以支持备用下载
                setTimeout(() => {
                    if (!this.fileUploadComponent.messageType) {
                        this.fileUploadComponent.showMessage('LOCAL_DOWNLOAD_FAILED', null, null);
                    }
                }, 3000);
            } else {
                // 网络不通畅则尝试获取备用地址后自动下载
                console.log(">>> 激活备用下载")
                this.loadAlternateDownloadAddress();
                this.doAlternateDownloadFile();
            }
        }
    },
    loadAlternateDownloadAddress() {
        const batchId = this.fileUploadComponent.batchResult.batchId;

        this.fileUploadComponent.isLoadingAlternateDownloadAddress = true

        const url = this.fileUploadComponent.Server.getCloudServiceAddress() + `/ossBatchItem/alternateDownloadAddress/${batchId}`;
        this.fileUploadComponent.Ajax.put(url).then(resp => {
            this.fileUploadComponent.isLoadingAlternateDownloadAddress = false
            if (resp.success) {
                const url = resp.data[0];
                if (url) {
                    this.fileUploadComponent.alternateDownloadAddress = url;
                } else {
                    this.fileUploadComponent.resetUpload();
                }
            }
        }, () => {
            this.fileUploadComponent.isLoadingAlternateDownloadAddress = false
        })
    },
    doAlternateDownloadFile() {
        if (!this.fileUploadComponent.alternateDownloadAddress) return;

        // 下载
        Common.downloadFile(this.fileUploadComponent.alternateDownloadAddress, null);
        // 点击下载就重置上传控件
        this.fileUploadComponent.resetUpload();
    },
    makeToast(variant = null) {
        if (!this.fileUploadComponent.batchResult.errorList || this.fileUploadComponent.batchResult.errorList.length === 0) return;

        const id = 'error-info';
        this.fileUploadComponent.$bvToast.hide(id);

        const errorLines = this.fileUploadComponent.batchResult.errorList.join(' ● ');
        this.fileUploadComponent.$bvToast.toast(errorLines, {
            id: id,
            title: `错误详情`,
            variant: variant,
            autoHideDelay: 5000,
            solid: true  // 设置后，用纯色而不是半透明的背景渲染组件
        })
    },

    dispatchWebsocketCustomEvent(websocketType, productCategory, batchResult) {
        // 创建自定义事件，参考：https://zh.javascript.info/dispatch-events
        let eventName = this.getWebsocketCustomEventName(websocketType, productCategory)
        //let event = new Event(eventName, {bubbles: true});  //不可以传参，改用 CustomEvent
        let event = new CustomEvent(eventName, {detail: batchResult});
        document.dispatchEvent(event, batchResult);
    },
    getWebsocketCustomEventName(websocketType, productCategory) {
        return `${websocketType}__${productCategory}`
    }
}