import { render, staticRenderFns } from "./MyRecord.vue?vue&type=template&id=34531056&scoped=true"
import script from "./MyRecord.vue?vue&type=script&lang=js"
export * from "./MyRecord.vue?vue&type=script&lang=js"
import style0 from "./MyRecord.vue?vue&type=style&index=0&id=34531056&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34531056",
  null
  
)

export default component.exports