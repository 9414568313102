//导入模块
import Vue from 'vue';
import VueRouter from 'vue-router';
import FileUpload from "../components/FileUpload";
import LoginForm from "../components/LoginForm";
import MembershipPackage from "../components/MembershipPackage";
import AllianceMarketing from "../components/AllianceMarketing";
import WithdrawalForm from "../components/WithdrawalForm";
import WithdrawalList from "../components/WithdrawalList";
import TransactionList from "../components/TransactionList";
import HumanAnimeFileUpload from "../pages/humananime/components/FileUpload";
import ImageEnlargeFileUpload from "../pages/imageenlarge/components/FileUpload";
import CleanerFileUpload from "../pages/cleaner/components/FileUpload";
import ImageMattingFileUpload from "../pages/imagematting/components/FileUpload";
import ImageVariationFileUpload from "../pages/imagevariation/components/FileUpload";
import ImageOutpaintFileUpload from "../pages/imageoutpaint/components/FileUpload";
import SubtitleFileUpload from "../pages/subtitle/components/FileUpload";
import WeixinPay from "../components/WeixinPay";
import ForgetPasswordForm from "@/components/ForgetPasswordForm.vue";
import Promotion from "@/components/Promotion.vue";
import ImageCompressionFileUpload from "@/pages/imagecompression/components/FileUpload.vue";
import SellingChannel from "@/components/SellingChannel.vue";
import PhotoAlbumStyleObjectDetail from "@/pages/photoalbum/components/StyleObjectDetail.vue";
import PhotoAlbumMyFavorites from "@/pages/photoalbum/components/MyFavorites.vue";
import PhotoAlbumMyRecord from "@/pages/photoalbum/components/MyRecord.vue";

//注册路由组件
Vue.use(VueRouter);

//编写路由规则
const routes = [
    {path: '/', component: FileUpload},
    {path: '/login', component: LoginForm},
    {path: '/forgetPassword', component: ForgetPasswordForm},
    {path: '/package', component: MembershipPackage},
    {path: '/weixinpay', component: WeixinPay},
    {path: '/alliancemarketing', component: AllianceMarketing},
    {path: '/withdrawal', component: WithdrawalForm},
    {path: '/withdrawallist', component: WithdrawalList},
    {path: '/transactionlist', component: TransactionList},
    {path: '/imageenlarge', component: ImageEnlargeFileUpload},
    {path: '/cleaner', component: CleanerFileUpload},
    {path: '/humananime', component: HumanAnimeFileUpload},
    {path: '/imagematting', component: ImageMattingFileUpload},
    {path: '/imagevariation', component: ImageVariationFileUpload},
    {path: '/imageoutpaint', component: ImageOutpaintFileUpload},
    {path: '/subtitle', component: SubtitleFileUpload},
    {path: '/imagecompression', component: ImageCompressionFileUpload},
    {path: '/promotion', component: Promotion},
    {path: '/sellingchannel', component: SellingChannel},
    {path: '/photoalbum/style/:uuid', component: PhotoAlbumStyleObjectDetail},
    {path: '/photoalbum/favorites', component: PhotoAlbumMyFavorites},
    {path: '/photoalbum/records', component: PhotoAlbumMyRecord}
];

//创建路由
const router = new VueRouter({
    // 创建路由时，可以选择路由模式mode,一共有三种模式：history、hash、abstract，常用的是history和hash。
    mode: 'history',
    routes
});

//导出
export default router;